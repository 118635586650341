@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import "primeng/resources/primeng.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";

body {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 12px;
}

input[type=text] {
    border: 1px solid rgba(153, 153, 153, 0.5);
    height: 27px;
    width: 140px;
    font-weight: 600;

    &:focus {
        outline: none;
    }
}

input[type=number] {
    border: 1px solid rgba(153, 153, 153, 0.5);
    height: 27px;
    width: 140px;
    font-weight: 600;

    &:focus {
        outline: none;
    }
}

label {
    font-size: 12px;
    color: #000000;
}

.header-label {
    font-size: 12px !important;
    color: #000000BF;
}

header {
    position: sticky;
    padding: 10px 20px 10px 20px;
    top: 0;
    color: white;
    z-index: 5;
    background-color: #5A4374;
    /* Ensure the background color covers the entire header */
    box-shadow: inset 0px 4px 4px 0px #00000040, 0px 4px 4px 0px #00000040;
}

.header-wrapper {
    max-height: 0;
    opacity: 0;
    transform: translateY(-10px);
    transition: max-height 0.3s ease, opacity 0.3s ease, transform 0.3s ease;
    overflow: hidden;
    right: 0px;
    top: 48px;
    z-index: 1;

    .navigation-options {
        width: 150px;
        background-color: #5A4374;
        color: white;
        box-shadow: inset 0px 4px 4px 0px #00000040, 0px 4px 4px 0px #00000040;
    }
}

.header-wrapper.dropdown-open {
    max-height: 200px;
    /* Adjust this based on the content height */
    opacity: 1;
    transform: translateY(0);
}

.title-section {
    background-color: #D9EAD5;
    position: sticky;
    top: 52px;
    /* Adjust this value based on the height of your header */
    z-index: 4;
}


/* Custom styles to override the arrow in ng-multiselect-dropdown */
.ng-multiselect-dropdown .dropdown-btn::after {
    content: "\e930";
    /* PrimeNG chevron down icon Unicode */
    font-family: 'PrimeIcons';
    font-size: 1em;
    /* Adjust size as needed */
    color: inherit;
    /* Inherit color from parent or customize */
    right: 10px;
    /* Positioning */
    position: absolute;
}


.pi-chevron-down:before {
    content: "\e930";
    /* The Unicode for the chevron down icon */
}


/* Ensure PrimeIcons are available */
@import 'primeicons/primeicons.css';

/* Custom styles for ng-multiselect-dropdown */
.ng-multiselect-dropdown .dropdown-btn {
    position: relative;
    /* Ensure relative positioning for absolute child */
}

.ng-multiselect-dropdown .dropdown-btn::after {
    content: "\e930";
    /* PrimeNG chevron down icon Unicode */
    font-family: 'PrimeIcons';
    font-size: 1em;
    /* Adjust size as needed */
    color: inherit;
    /* Inherit color from parent or customize */
    right: 10px;
    /* Positioning */
    top: 50%;
    /* Position in the middle */
    transform: translateY(-50%);
    /* Center vertically */
    position: absolute;
}

.reasons-checkbox{
    input[type=checkbox]{
        accent-color: #5A4374 !important;
    }
}

.bg-purple {
    background-color: #5A4374;
}

.font-purple {
    color: #5A4374;
}

.bg-gray {
    background-color: #EAEAEA;
}

.bg-green {
    background-color: #D9EAD5;
}